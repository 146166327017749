import Head from "next/head"
import Link from "next/link"
import { getClient, sanityClient } from "../../modules/common/lib/cms/cms.server"
import { unitPathsQuery, unitQuery } from "../../modules/common/lib/cms/queries"
import DefaultErrorPage from "next/error"
import { useRouter } from "next/router"

const Unit = ({ data }) => {
  const router = useRouter()
  const unit = data?.unit

  if (router.isFallback) {
    return <div className="px-4 py-3">Loading...</div>
  }

  if (!unit) {
    return (
      <>
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
        <DefaultErrorPage statusCode={404} />
      </>
    )
  }

  return (
    <div className="app-wrapper">
      <Head>
        <title>{unit.title} | Skillfyre</title>
      </Head>
      <div className="max-w-lg mx-auto">
        <h1 className="text-xl font-bold text-center text-gray-900 md:text-2xl lg:text-3xl">
          {unit.title}
        </h1>

        <div className=" border border-gray-200 rounded-md divide-y my-3">
          {unit.modules && unit.modules.length ? (
            unit.modules.map((m) => (
              <div key={m._key}>
                <Link
                  href={{
                    pathname: "/[unit]/[module]",
                    query: { unit: unit.slug, module: m.slug },
                  }}>
                  <a className="flex items-center justify-between px-6 py-4 hover:bg-gray-50">
                    <h2 className="text-base md:text-lg">{m.title}</h2>
                  </a>
                </Link>
              </div>
            ))
          ) : (
            <div className="px-4 py-3">No modules for this unit yet.</div>
          )}
        </div>
      </div>
    </div>
  )
}

export async function getStaticProps({ params, preview = false }) {
  const unit = await getClient(preview).fetch(unitQuery, {
    unit: params.unit,
  })

  return {
    props: {
      preview,
      data: {
        unit,
      },
    },
    revalidate: 60 * 5,
  }
}

export async function getStaticPaths() {
  const paths = await sanityClient.fetch(unitPathsQuery)
  return {
    paths: paths.map((unit) => ({ params: { unit } })),
    fallback: true,
  }
}

Unit.authRequired = true
export default Unit
